import React from "react";

import { ChatEngine } from 'react-chat-engine'

import ChatFeed from "./components/ChatFeed";
import LoginForm from "./components/LoginForm";
// import "./temp.css";
import "./App.css";

const projectID = "b8c90230-bb65-41f4-acbf-26591f351933";

const App = () => {
  if (!localStorage.getItem("username")) return <LoginForm />;

  return (
    <ChatEngine
      height="100vh"
      projectID={projectID}
      userName={localStorage.getItem("username")}
      userSecret={localStorage.getItem("password")}
      renderChatFeed={(chatAppProps) => <ChatFeed {...chatAppProps} />}
      onNewMessage={() =>
        new Audio(
          "http://codeskulptor-demos.commondatastorage.googleapis.com/pang/pop.mp3"
        ).play()
      }
    />
  );
};

// infinite scroll, logout, more customizations...

export default App;
